<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Your Plan</strong>
      </CCardHeader>
      <CCardBody>
        <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0"
                v-if="typeof user.lastBought !== 'undefined' && user.lastBought !== null">
            <p>
              Plan start time:{{ user.lastBought.datetime * 1000 | dateFormat }} <br>
              Total time:{{ user.lastBought.shop.info.expire }} Days <br>
              Reset bandwidth each <b>{{ user.lastBought.shop.info.reset }}</b> Day
            </p>
            <br>
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0" v-else>
            <strong>No available plan.</strong><br>
            <strong>You're in free trial mode.</strong><br>
            <strong>Please contact your sales manager.</strong>
            <br>
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Expire Time:</div>
            <strong>{{ user.accountExpireDays >= 0 ? user.accountExpireDays + "Days left" : "You Are Expired!"
              }}</strong><strong
            v-if="typeof user.lastBought !== 'undefined' && user.lastBought !== null">({{ (user.expirePercent) | percent
            }}Left.)</strong>
            <CCardText>
              Expire Time:{{ user.expireTime }}
            </CCardText>
            <CProgress
              class="progress-xs mt-1"
              animated
              show-value
              showPercentage
              style="height:20px;"
              :precision="1"
              :color="color(user.expirePercent)"
              v-if="typeof user.lastBought !== 'undefined' && user.lastBought !== null && user.accountExpireDays <= user.lastBought.shop.info.expire"
              :value="user.expirePercent"
            />
            <br>
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Bandwidth Usage</div>
            <strong>Used:{{ user.usedTraffic }}({{ (100 - user.trafficUsagePercent) | percent }}Left.)</strong>
            <CCardText>Total:{{ user.enableTraffic }}</CCardText>
            <CProgress
              class="progress-xs mt-1"
              :precision="1"
              animated
              show-value
              showPercentage
              style="height:20px;"
              :color=color(100-user.trafficUsagePercent)
              :value="100-user.trafficUsagePercent"
            />
            <br>
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Speed Limit:</div>
            <strong>{{ user.speedLimit === 0 ? "No Limit" : user.speedLimit + "Mbps" }}</strong>
            <div class="text-muted">Client Limit:</div>
            <strong>{{ user.clientLimit === 0 ? "No Limit" : user.clientLimit }}</strong>
            <br>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Connection</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                Connection Guide
              </CCardHeader>
              <CCardBody>
                <CTabs variant="tabs">
                  <CTab active>
                    <template slot="title">
                      <CIcon name="cib-apple" />
                      IOS Phone
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Setup:
                            <CListGroup>
                              <CListGroupItem>
                                <CCardText>1.Download the App "Shadowrocket" via Apple App Store.</CCardText>
                                <CCardText>Please get your download account / password from your sales manager.
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>2.Open Shadowrocket.Then click "+" button upper in the right corner.Select
                                  type "Subscribe".
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>3.Copy the subscribe link under.Paste into the "URL" box.Then click "Finish"
                                  button.
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>4.Select a node which network test is passed.Then switch on the connect
                                  button.
                                </CCardText>
                              </CListGroupItem>
                              <!--                              <CListGroupItem>-->
                              <!--                                <CCardText>5.Video guide->-->
                              <!--                                  <CLink href="https://v.airmy.life/guide/ios-ssrocket" target="_blank">Click here-->
                              <!--                                  </CLink>-->
                              <!--                                </CCardText>-->
                              <!--                              </CListGroupItem>-->
                            </CListGroup>
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Subscribe Link:"
                              id="subscribeIOSLink"
                              :value="user.subLink"
                              readonly
                            >
                              <template #prepend-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge class="copy" @click="copyCode" data-clipboard-target="#subscribeIOSLink">
                                      <CLink>Copy</CLink>
                                    </CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                              <template #append-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge @click="">Regenerate</CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                            </CInput>
                            <CAlert
                              color="success"
                              :show.sync="copiedMsg"
                              closeButton
                            >
                              <b>Copied!</b>
                            </CAlert>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                  <CTab>
                    <template slot="title">
                      <CIcon name="cib-android" />
                      Android Phone(New program)
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Setup:
                            <CListGroup>
                              <CListGroupItem>
                                <CCardText>1.Download the App "Shadowsocks-Android"
                                  <CBadge color="primary"
                                          href="https://v.airmy.life/guide/android/shadowsocks-client.apk"
                                          target="_blank">
                                    <CIcon name="cil-cloud-download" />
                                    Download
                                  </CBadge>
                                  .
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>2.Open "Shadowsocks".Then click "≡" button upper in the left top.Select menu
                                  "Subscribe".
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>3.Copy the subscribe link under.Click the button "≡+" in the right top.Paste
                                  into the "URL" box.Then click "Finish" button.
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>4.Select a node which network test is passed.Then switch on the connect
                                  button.
                                </CCardText>
                              </CListGroupItem>
                              <!--                              <CListGroupItem>-->
                              <!--                                <CCardText>5.Video guide->-->
                              <!--                                  <CLink href="https://v.airmy.life/guide/android" target="_blank">Click here</CLink>-->
                              <!--                                </CCardText>-->
                              <!--                              </CListGroupItem>-->
                            </CListGroup>
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Subscribe Link:"
                              id="subscribeAndroidLinkNew"
                              :value="'https://link.airmy.life/sub?target=sssub&url='+encodeURIComponent(user.subLink)+'&insert=false'"
                              readonly
                            >
                              <template #prepend-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge class="copy" @click="copyCode"
                                            data-clipboard-target="#subscribeAndroidLinkNew">
                                      <CLink>Copy</CLink>
                                    </CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                              <template #append-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge @click="">Regenerate</CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                            </CInput>
                            <CAlert
                              color="success"
                              :show.sync="copiedMsg"
                              closeButton
                            >
                              <b>Copied!</b>
                            </CAlert>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                  <CTab>
                    <template slot="title">
                      <CIcon name="cib-android" />
                      Android Phone(Old program)
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Setup:
                            <CListGroup>
                              <CListGroupItem>
                                <CCardText>1.Download the App "Shadowsocksr-Android"
                                  <CBadge color="primary"
                                          href="https://v.airmy.life/guide/android/shadowsocksr-client.apk"
                                          target="_blank">
                                    <CIcon name="cil-cloud-download" />
                                    Download
                                  </CBadge>
                                  .
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>2.Open "SSRR".Then click "+" button upper in the right corner.Select type
                                  "Subscribe".
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>3.Copy the subscribe link under.Paste into the "URL" box.Then click "Finish"
                                  button.
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>4.Select a node which network test is passed.Then switch on the connect
                                  button.
                                </CCardText>
                              </CListGroupItem>
                              <!--                              <CListGroupItem>-->
                              <!--                                <CCardText>5.Video guide->-->
                              <!--                                  <CLink href="https://v.airmy.life/guide/android" target="_blank">Click here</CLink>-->
                              <!--                                </CCardText>-->
                              <!--                              </CListGroupItem>-->
                            </CListGroup>
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Subscribe Link:"
                              id="subscribeAndroidLinkOld"
                              :value="user.subLink"
                              readonly
                            >
                              <template #prepend-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge class="copy" @click="copyCode"
                                            data-clipboard-target="#subscribeAndroidLinkOld">
                                      <CLink>Copy</CLink>
                                    </CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                              <template #append-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge @click="">Regenerate</CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                            </CInput>
                            <CAlert
                              color="success"
                              :show.sync="copiedMsg"
                              closeButton
                            >
                              <b>Copied!</b>
                            </CAlert>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                  <CTab>
                    <template slot="title">
                      <CIcon name="cib-windows" />
                      Windows PC
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Setup:
                            <CListGroup>
                              <CListGroupItem>
                                <CCardText>1.Download the program "AirmyLife.zip"
                                  <CBadge color="primary"
                                          href="https://v.airmy.life/guide/windows/AirmyLife.zip"
                                          target="_blank">
                                    <CIcon name="cil-cloud-download" />
                                    Download
                                  </CBadge>
                                  .
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>2.Open the file.<b style="color: red">EXTRACT ALL THE FILE TO A FOLDER</b>.
                                  Run "AirmyLife-Client.exe".Login with your account info.</CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>3.After login success the window will disappear.You can see a ✈️ logo at taskbar(right side).
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>4.Select a node to use.
                                </CCardText>
                              </CListGroupItem>
<!--                              <CListGroupItem>-->
<!--                                <CCardText>5.Video guide->-->
<!--                                  <CLink href="https://v.airmy.life/guide/android" target="_blank">Click here</CLink>-->
<!--                                </CCardText>-->
<!--                              </CListGroupItem>-->
                            </CListGroup>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                  <CTab>
                    <template slot="title">
                      <CIcon name="cil-laptop" />
                      MacOS PC
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Setup:
                            <CListGroup>
                              <CListGroupItem>
                                <CCardText>1.Download the Image "ShadowsocksX-NG-R8"
                                  <CBadge color="primary"
                                          href="https://v.airmy.life/guide/macos/macos-client.zip"
                                          target="_blank">
                                    <CIcon name="cil-cloud-download" />
                                    Download
                                  </CBadge>
                                  .
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>2.Open the file.Then copy ShadowsocksX-NG-R8.app to <b style="color: red">Finder->Application</b>
                                  folder.(Do not put it on "Desktop")
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>3.Open the ShadowsocksX-NG-R8 in "Launchpad".Then you can see a flight icon
                                  on the top.
                                </CCardText>
                              </CListGroupItem>
                              <CListGroupItem>
                                <CCardText>4.Click the flight icon.Then turn on the "Shadowsocks".</CCardText>
                              </CListGroupItem>
<!--                              <CListGroupItem>-->
<!--                                <CCardText>5.Video guide->-->
<!--                                  <CLink href="https://v.airmy.life/guide/android" target="_blank">Click here</CLink>-->
<!--                                </CCardText>-->
<!--                              </CListGroupItem>-->
                            </CListGroup>
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Subscribe Link:"
                              id="subscribeMacosLink"
                              :value="user.subLink"
                              readonly
                            >
                              <template #prepend-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge class="copy" @click="copyCode" data-clipboard-target="#subscribeMacosLink">
                                      <CLink>Copy</CLink>
                                    </CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                              <template #append-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge @click="">Regenerate</CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                            </CInput>
                            <CAlert
                              color="success"
                              :show.sync="copiedMsg"
                              closeButton
                            >
                              <b>Copied!</b>
                            </CAlert>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                  <CTab>
                    <template slot="title">
                      <CIcon name="cil-info" />
                      Manual Setting
                    </template>
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="12">
                            Your Connection Information:
                          </CCol>
                          <CCol sm="12">
                            Port: <b>{{ user.port }}</b>
                          </CCol>
                          <CCol sm="12">
                            Password: <b>{{ user.passwd }}</b>
                          </CCol>
                          <CCol sm="12">
                            Method: <b>{{ user.method }}</b>
                          </CCol>
                          <CCol sm="12">
                            Obfs Type: <b>{{ user.obfs }}</b>
                          </CCol>
                          <CCol sm="12">
                            Obfs Param: <b>{{ user.obfs_param }}</b>
                          </CCol>
                          <CCol sm="12">
                            Protocol: <b>{{ user.protocol }}</b>
                          </CCol>
                          <CCol sm="12">
                            Protocol Param: <b>{{ user.protocol_param }}</b>
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Subscribe Link:"
                              id="subscribeLink"
                              :value="user.subLink"
                              readonly
                            >
                              <template #prepend-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge class="copy" @click="copyCode" data-clipboard-target="#subscribeLink">
                                      <CLink>Copy</CLink>
                                    </CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                              <template #append-content>
                                <CRow>
                                  <CCol sm="6">
                                    <CBadge @click="">Regenerate</CBadge>
                                  </CCol>
                                </CRow>
                              </template>
                            </CInput>
                            <CAlert
                              color="success"
                              :show.sync="copiedMsg"
                              closeButton
                            >
                              <b>Copied!</b>
                            </CAlert>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                </CTabs>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import user from "@/views/users/User";
import ClipboardJS from "clipboard";

function DateParse(str_date) {
  var str_date_splited = str_date.split(/[^0-9]/);
  return new Date(str_date_splited[0], str_date_splited[1] - 1, str_date_splited[2], str_date_splited[3], str_date_splited[4], str_date_splited[5]);
}

export default {
  name: "Index",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand
  },
  data() {
    return {
      user: [],
      copiedMsg: 0
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/index", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.user = response.data.user;
            let nowDate = new Date();
            this.user.accountExpireDays = Math.floor((DateParse(this.user.expireTime) - nowDate.getTime()) / (24 * 3600 * 1000));
            if (this.user.lastBought !== null) {
              this.user.lastBought.shop.info = JSON.parse(this.user.lastBought.shop.content);
              if (this.user.accountExpireDays > this.user.lastBought.shop.info.expire) {
                this.user.expirePercent = 100;
              } else if (this.user.accountExpireDays < 0) {
                this.user.expirePercent = 0;
              } else {
                this.user.expirePercent = (this.user.accountExpireDays / this.user.lastBought.shop.info.expire) * 100;
              }
              console.log(this.user.accountExpireDays);
              console.log(this.user.expireTime);
            }
          }
        });
    },
    copyCode() {
      let clipboard = new ClipboardJS(".copy");
      clipboard.on("success", e => {
        this.copiedMsg = 3;
      });
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "danger";
      } else if (value > 25 && value <= 50) {
        $color = "warning";
      } else if (value > 50 && value <= 75) {
        $color = "info";
      } else if (value > 75 && value <= 100) {
        $color = "success";
      }
      return $color;
    }
  }
};
</script>
